import React, { useState, useEffect } from 'react'
import logo from '../../assets/logo.svg'
import EventEmmiter from '../../EventEmmiter'
import close from '../../assets/icons/close.svg'

export const showPrivacy = new EventEmmiter

export default () => {
    const [ visible, setVisible ] = useState(false)

    useEffect(() => {
        showPrivacy.on('open_privacy_modal', () => {
            setVisible(true)
        })

    }, [])

    const closePrivacy = e => {
        e.preventDefault()
        setVisible(false)
    }

    return visible ?
        <div className="custom-modal">
            <div className="custom-modal-in">
                <header className="py-4 pl-4 pr-2 pl-md-5 pr-md-4 d-flex justify-content-between align-items-center">
                        <h2 className="text-center pt-2">Privacy Policy</h2>
                    <div className="">
                        <a href="#" onClick={closePrivacy} className="close"><img src={close} alt='close' /></a>
                    </div>
                </header>
                <div className="body p-4 p-md-5">
                    <div className="">

                        <div className="">
                            <p>Your privacy is important to us, and we want you to understand what we collect, what we don’t collect, and what we do with the information that we do collect. This Privacy Policy (the “Policy”) applies to the information we collect about visitors to http://spidersilk.hk/ (the “Site”) and subscribers to the Spidersilk VPN and related service (the “Services”), which are owned and operated by Spidersilk (contact information below).</p>
                            {/* <p>Our Terms of Use, including their limitation on liability, apply to the Privacy Policy. You can read them at <a href="#" className="underline cl-black">terms of service/</a></p> */}
                        </div>

                        <div className="">
                            <h3 align="center center">Information We <span className="cl-red">Collect</span></h3>
                            <p>Spidersilk does not collect or log any traffic or use of its Virtual Private Network service.</p>
                            <p>You do not have to give us any personally identifiable (“personal”) information to browse our Site, but to subscribe to our Services you must create an account and register with us. In general, we collect the following limited information from users and only use it in order to process payments:</p>
                            <p>Account Information. To create an account, you may need to provide certain personal information, such as your first name and e-mail. You will also need to select a username and password and provide us with payment information. The type of information collected may vary depending on payment method chosen. We will only use this information to collect and process payments for you. Once you have created an account, billing and subscription information can be accessed and modified by you at any time via your control panel.</p>
                            <p>Site Visitors. We collect certain information automatically from visitors to our Site, such as IP (Internet protocol) address and pages viewed on our Site. For more information, see the “Cookies and Tracking” section below. However, we do not track user activities outside of our Site, nor do we track the browsing activities of users who are logged into our VPN service.</p>
                        </div>

                        <div className="">
                            <h3 align="center center">How We <span className="cl-red">Use Your Information</span></h3>
                            <p>We do not sell or rent personal information to others.</p>
                            <p>The information we collect through our Site and Services is primarily used to create new accounts, collect payment for our Services, identify and communicate with current subscribers, or offer troubleshooting or customer support. We may also use personal information for the following reasons:</p>
                            <ul>
                                <li>To verify your identity when you login to the Site or Service;</li>
                                <li>For internal record keeping;</li>
                                <li>To complete your online order;</li>
                                <li>To administer and improve our Site and Services;</li>
                                <li>To target our Site content to your preferences;</li>
                                <li>To respond to your requests or inquires and for other customer service purposes;</li>
                                <li>So that we may periodically send you promotional information that we think may interest you, about products, services, and offers made available by us, our affiliates or our marketing partners; and</li>
                                <li>To analyze usage and trends on our Site and to better understand how users use the Site and Services.</li>
                                <li>Service Providers. With third party service providers who provide services on our behalf, such as processing payments, hosting our Site, and sending email messages for us. Our service providers receive only the information they need to perform their designated functions, and are not permitted to use or disclose this information for their own marketing or other purposes;</li>
                                <li>Aggregate, Anonymous or De-Identified Information. Sharing aggregate or non-personally identifiable information with non-affiliated third parties for advertising, marketing or research purposes. However, we do not share your personal information with any third parties for their own marketing, advertising or research purposes.</li>
                            </ul>
                        </div>

                        <div className="">
                            <h3 align="center center"><span className="cl-red">Cookies</span></h3>
                            <p>This section describes the information we collect about visitors to our Site. As noted above, we do not track user activities outside of our Site, nor do we track the web activities of users when they are logged into our VPN Services.</p>
                            <p>We may use or engage others to use cookies, clear gifs, and similar technologies to understand how users access and use our Site. In general, you can browse our Site without telling us who you are or submitting any personal information. However, we collect the IP addresses of all visitors to our Site, as well as other related information such as page requests, browser type, operating system and average time spent on our Site. We use this information to help us understand Site activity and to monitor and improve our Site.</p>
                            <p>Cookies. Cookies are alphanumeric identifiers that we transfer to your computer’s hard drive through your web browser for record-keeping purposes. There are two types of cookies: session-based and persistent-based cookies. Session cookies exist only during an online session; we use session cookies to allow our systems to uniquely identify you while you are logged in to your account at the Site. Persistent cookies remain on your computer after you have closed your browser or turned off your computer. We use persistent cookies to track aggregate and statistical information about user activity.</p>
                            <p>Disabling Cookies. Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Site visitors who disable their web browsers’ ability to accept cookies will be able to browse the Site but certain Site features will not function.</p>
                            <p>Clear GIFs (Web Beacons/Web Bugs). We or the third parties we engage may employ clear GIFs (a.k.a. Web Beacons/Web Bugs) to help us better manage content on the Site by among other things, tracking the activities of our Site visitors and compiling statistics about Site usage. Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of Web users. In contrast to cookies, which are stored on your computer’s hard drive, clear gifs are embedded invisibly on Web pages or within emails.</p>
                            <p>Third Parties. We may engage third parties, such as Google Analytics, to track and analyze non-personally identifiable Site data. These third parties may use cookies and clear GIFs on our Site to collect, analyze, and generate reports on Site usage for us. We do not provide these third parties with any personal information that we have collected about you. Use the following link to opt out of Google’s Analytic tracking: https://tools.google.com/dlpage/gaoptout</p>
                        </div>

                        <div className="">
                            <h3 align="center center"><span className="cl-red">Security</span> Measures We Have In Place</h3>
                            <p>We use security measures that are consistent with industry standards to protect your personal information from loss, theft, misuse or unauthorized access or disclosure or destruction. All employees are kept up-to-date on our security and privacy practices. In addition, access to user or subscriber personal information is restricted to those who need to know such information to perform their job functions.</p>                   
                            <p>Where appropriate, we use encryption, access controls, passwords, and/or physical security measures to protect the personal information we collect and maintain about you against unauthorized access and disclosure. Despite our efforts, no data security methods guarantee 100% protection. We do not promise and cannot guarantee that your personal information can never be viewed and/or used by others.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    : null
}

