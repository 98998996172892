import React, { useEffect } from 'react'
import Form from './Form'
import EventEmmiter from '../../EventEmmiter'

export const scrollContact = new EventEmmiter

export default () => {
    useEffect(() => {
    
        scrollContact.on('scroll_to_contact', function(){
            let top = document.querySelector('.contact').offsetTop
            window.scrollTo(0, top)
        })

    }, [])

    return <section className="contact">
            <div className="contact-form bg-red">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h2 className="py-5 py-md-3 ff-bold fs-24 fs-md-40 cl-white text-center text-md-left">We have staff ready to <br />help you succeed.</h2>
                        </div>
                        <div className="col-12 col-md-6">
                            <Form />
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="container mt-md-5">
                <div className="contact-info pb-4 pb-md-2">
                    <div className="row text-center text-md-left">
                        <div className="col-12 col-md-6">
                            <h2 className="pt-5 pb-4 py-md-5 ff-bold fs-24 fs-md-40">SpiderSilk Limited</h2>
                            <p className="ff-bold fs-16 ff-md-20"><span className="d-md-block">Suite C, Level 7 World Trust Tower, 50 Stanley Street,</span> Central Hong Kong`</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <h2 className="py-4 py-md-5 pb-4 py-md-5 ff-bold fs-24 fs-md-40">Contact Us</h2>
                            <address className="mb-md-4">
                                <div className="ff-bold cl-red fs-16 ff-md-20">+852 8193 2972</div> 
                                <a href="mailto:contact@spidersilk.hk"  className="ff-bold cl-red fs-16 ff-md-20">contact@spidersilk.hk</a> 
                            </address>
                        </div>
                    </div>
                </div>
            </div>
      
    </section>
}

