import React from 'react'
import poligon from '../../assets/icons/poligon.svg'
import poligonBig from '../../assets/icons/poligon-big.svg'
import arrow from '../../assets/icons/arrow.svg'

export default () => {
    const scrollDown = (e) => {
        e.preventDefault();
        
        const el = document.querySelector('.services')
        if( el ) window.scrollTo(0, el.offsetTop)
    }

    return <React.Fragment>
        <div className="poligons-background pt-3 pt-md-0">
            <div className="row">
                <div className="col-12 col-lg-4"></div>
                <div className="col-12 col-lg-8 text-right">
                    <div className="p-container">
                        <img src={poligonBig} className=" p-center" />
                        <img src={poligon} className="absolute p-small" />
                        <img src={poligon} className="absolute p-middle" />
                        <img src={poligon} className="absolute p-big" />
                    </div>
                </div>
            </div>
        </div>
        <div className="head-text pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 pr-md-5">
                        <div className="">
                            <h1 className="mt-md-5 pt-md-5 ff-bold fs-30 fs-md-50 text-center text-md-left">
                                <span className="cl-red">SpiderSilk</span> the <br />foundation for your <br />success
                            </h1>
                            <div>
                                <p className="d-none d-md-block fs-md-20 pt-5">SpiderSilk delivers military grade security and privacy applications to the masses, connecting business branches fully encrypted together and providing custom privacy solutions.</p>
                                <div className="d-md-none text-center pt-4">
                                    <a href="#" onClick={scrollDown}><img src={arrow} className="rotate-90 w-11 w-md-auto" /></a>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div className="col-12 col-lg-6"></div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

