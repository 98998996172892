import React, { useState } from 'react';


export default () => {

    const [ form, setForm ] = useState({
        name: '',
        email: '',
        message: ''
    })

    const [ errors, setErrors ] = useState({})
    const [ wairing, setWairing ] = useState(false)
    const [ message, setMessage ] = useState('')
    const [ submit, setSubmit ] = useState(true)
    

    const handleForm = e => {
        e.preventDefault();
        

            if( !validateName(form.name) )
            { 
                return setErrors({name: true})
            }
            else if( !validateEmail(form.email) )
            {
                return setErrors({email: true})
            }
            else if( !validateMessage(form.message) ){
                return setErrors({message: true})
            }

            sendMessage()
        
    }

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
            return true
        }
        return false
    }

    
    const validateName = (name) => {
        if (/([A-Za-z])\w+/.test(name)){
            return true
        }
        return false
    }

        
    const validateMessage = (message) => {
        if (/([A-Za-z0-9])\w+/.test(message)){
            return true
        }
        return false
    }

    const handleEnter = (e) => {
        if( e.charCode == 13 ){
            // handleForm(e)

        }
    }

    const changeFieldHandler = (event) => {
        setForm({...form, [event.target.getAttribute('data-name')]: event.target.value})
    }

    const sendMessage = () => {
        // return setMessage( 'setMessage( response.success_message  setMessage( response.success_message ))' )

        if( !submit ) return false

        setSubmit(false)

        var newForm = new FormData();

        for (const dt in form) {
            newForm.append(dt, form[dt])
        }

        let url = '/mail.php'

        setWairing(true)
     
        fetch(url, {
            method: 'post', 
            body:  newForm
        })
        .then((response) => {
            setSubmit(true)
            return response.json();
        })
        .then((myJson) => {
            let response = myJson //JSON.stringify(myJson)
      
            if( response.error_code === 1 ){
               //error
               console.log(response.errors)
               console.log(response.errors.name)
        
               if( response.errors.name ){
                    setErrors({name: true})
               }else if( response.errors.email ){
                    setErrors({email: true})
               }else if( response.errors.message ){
                    setErrors({message: true})
               }
            }else{
                setMessage( response.success_message )
            } 
            setWairing(false)
        });
    }


    const clearError = () => {
        return setErrors({})
    }

    return(
        <div className='bg-white p-4 p-md-5'>
            { message && message.length > 0 ? 
                <div className='py-3 fs-20 text-center up'>{message}</div>
                :
                <form>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className={errors.name ? 'form-field mb-3 mb-md-5 error' : 'form-field mb-3 mb-md-5 '} >
                                <input type='text' autoComplete={"nope"}  onChange={changeFieldHandler} onKeyPress={handleEnter} data-name='name' onFocus={clearError} value={form.name} placeholder='Your Name Here' />
                                { errors.name ? <div  className='text-danger fs-14 my-2'>Name must be at least 2 character</div> : ''}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className={errors.email ? 'form-field mb-3 mb-md-5 error' : 'form-field mb-3 mb-md-5 '} >
                                <input type='text' autoComplete={"nope"} onChange={changeFieldHandler} onKeyPress={handleEnter} data-name='email' onFocus={clearError} value={form.email} placeholder='Your Email Here' />
                                { errors.email ? <div className='text-danger fs-14 my-2'>Invalid email format</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className={errors.message ? 'form-field mb-3 mb-md-5 error' : 'form-field mb-3 mb-md-5 '}  >
                        <textarea onChange={changeFieldHandler} onKeyPress={handleEnter} data-name="message" onFocus={clearError} value={form.message} placeholder='Your Message Here' rows='1'></textarea>
                        { errors.message ? <div className='text-danger fs-14 my-2'>Message must be at least 2 character</div>: ''}
                    </div>

                    {wairing 
                        ? 
                        <div className="waiting">Waiting for response...</div>
                        :
                        <div className='text-right ff-bold button'>
                            <button onClick={(event) => handleForm(event)} className={'button cl-red fs-20'} disabled={!submit}>
                                -Send
                            </button>
                        </div>
                    }
                    
                </form>
            }
        </div>
    )
}