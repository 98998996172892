import React, { useState, useEffect } from 'react';
import './App.scss';
import Header from './components/partials/Header'
import Footer from './components/partials/Footer'
import First from './components/sections/First'
import Services from './components/sections/Services'
import Contact from './components/sections/Contact'
import Privacy from './components/partials/Privacy'


function App() {

  useEffect(() => {

  }, [])




  return (
    <div className="App">
        <Header />
        <First />
        <Services />
        <Contact />
        <Privacy />
        <Footer />
    </div>
  );
}

export default App;
