import React from 'react'
import logo from '../../assets/logo.svg'
import { scrollContact } from '../sections/Contact'

export default () => {
    const scrollToContactForm = (event) => {
        event.preventDefault();
        
        // const emmiter = new EventEmmiter()
        scrollContact.trigger('scroll_to_contact')
    }

    return <header className="py-4 px-2 py-md-5">
        <div className="container">
            <div className="row">
            <div className="col-6">
                <img src={logo} className="logo" />
            </div>
            <div className="col-6 text-right">
                <a href="#" onClick={scrollToContactForm} className="d-inline-block ff-bold fs-16 button button-border px-4 py-3">Hire Us</a>
            </div>
            </div>
        </div>
    </header>
}

