import React, { useState, useEffect } from 'react'
import arrow from '../../assets/icons/arrow.svg'

export default () => {
    const [width, setWidth] = useState(0)

    const resizeHandler = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        document.addEventListener('resize', resizeHandler, false)
        resizeHandler()
    }, [])

    const services = [
        {image: 'business', name: 'Business <br/>VPN'},
        {image: 'vpn', name: 'VPN <br/>Servers'},
        {image: 'software', name: 'VPN <br/>Software Application <br/>Development'},
        {image: 'security', name: 'Server Security <br/>and Patches'},
        {image: 'privacy', name: 'Custom Privacy <br/>Solution.'}
    ]
    

    return <section className="services py-5 mt-5" style={{background: `url(${require('../../assets/line_bg.svg')}) top 300px center / 100% no-repeat`}}>
        <div className="container">
            <h2 className="ff-bold fs-16 fs-md-30 text-center my-md-5">Contact us for more information <br />if you are interested in:</h2>
            <div className="row justify-content-center px-3 px-md-0 pt-5">
                {services.map(serv => {
                    return <div key={serv.image} className="col-12 col-md-6 mb-4 pb-2">
                        <div className="p-3 h-100 bg-white shadowed min-h-80 min-h-md-150">
                            <div className="px-2 px-md-4 h-100 d-flex justify-content-between align-items-center">
                                <img src={require(`../../assets/icons/${serv.image}.svg`)} alt={serv.image} className="w-28 w-md-auto" />
                                <h3 className='fs-16 fs-md-28 text-center' dangerouslySetInnerHTML={{ __html: serv.name }}></h3>
                                <img src={arrow} className="w-11 w-md-auto" />
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </section>
}

