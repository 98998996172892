import React from 'react'
import tw from '../../assets/icons/tw.svg'
import lin from '../../assets/icons/in.svg'
import fb from '../../assets/icons/fb.svg'
import { showPrivacy } from '../partials/Privacy'

export default () => {
    const showPrivacyPolicy = e => {
        e.preventDefault();
        showPrivacy.trigger('open_privacy_modal')
    }

    return <footer className="py-5">
        <div className="container">
            <div className="row flex-row-reverse">
                <div className="col-12 col-md-6">
                    <div className="socials">
                        <ul className="m-0 p-0 d-flex justify-content-center justify-content-md-start">
                            {/* <li><a href="#" className="px-3 p-md-0 pr-md-4"><img src={tw} alt={tw} /></a></li> */}
                            <li><a href="https://www.linkedin.com/company/spidersilk-limited/" target="_blank" className="px-3 p-md-0 pr-md-4"><img src={lin} alt={lin} /></a></li>
                            <li><a href="https://www.facebook.com/SpiderSilk-Limited-101836918170722" target="_blank" className="px-3 p-md-0 pr-md-4"><img src={fb} alt={fb} /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="fs-16 text-center text-md-left pt-4 pt-md-0">
                        <a href="#" onClick={showPrivacyPolicy} className="underline cl-black">Privacy Policy</a>
                        <span className="op-5 d-block d-md-inline-block pl-md-4 pt-3">Copyright © 2020. All rights reserved</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}